export const tagColors = [
	"#1A4D8F",
	"#3455DB",
	"#00707B",
	"#2B4C3F",
	"#58792D",
	"#E97451",
	"#D8412F",
	"#D87C30",
	"#6B2D5C",
	"#7D3C98"
]